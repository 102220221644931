import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import "./multipleSelect.scss";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'StationCompleted',
    'RailwayBought',
    'RailwayUpgraded',
    'SortingSlideBought',
    'SortingSlideUpgraded',
    'OperatorRoomBought',
    'OperatorBought',
    'OperatorUpgraded',
    'OperatorConsoleUpgraded',
    'LocomotiveBought',
    'DepotUpgraded',
    'OfflineIncomeUpgraded',
    'TutorialCompleted',
];

type Props = {
    selectedElements: string[];
};

export default function MultipleSelectCheckmarks(props: Props) {
    const startSelectedElements = props.selectedElements

    const [personName, setPersonName] = React.useState<string[]>(startSelectedElements);

    const handleChange = (event: SelectChangeEvent<typeof personName>) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        props.selectedElements.length = 0
        for (var i = 0; i < value.length; i++) {
                    props.selectedElements.push(value[i])
                }
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }} className="formControl" >
                <InputLabel id="demo-multiple-checkbox-label" className="inputLabel" >Событие</InputLabel>
                <Select
                    className="select"
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {names.map((name) => (
                        <MenuItem key={name} value={name} className="menuItem">
                            <Checkbox checked={startSelectedElements.includes(name)} className="checkbox"/>
                            <ListItemText primary={name} className="listItemText"/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}