import {
    DataGrid,
    GridColDef,
    GridToolbar,
} from "@mui/x-data-grid";
import "./funnelDashboard.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import React, {useState} from "react";
import {Console} from "inspector";
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
// import { useMutation, useQueryClient } from "@tanstack/react-query";

type Props = {
    // getAnalyticsUrl: string;
    data: any;
};

const FunnelDashboard = (props: Props) => {
    // const getAnalyticsUrl = props.getAnalyticsUrl;
    // const data = [{'name': 'TestLevel1', 'eventCount': 5.0}, {'name': 'TestLevel2', 'eventCount': 4.0}, {'name': 'TestLevel3', 'eventCount': 2.0}, {'name': 'TestLevel4', 'eventCount': 1.0}];

    const sortedData = props.data.sort((a:any,b:any) => b.eventCount - a.eventCount)
    return (
        // <ResponsiveContainer width="99%" height={550}>
        <div className="barChartDiv">
            <BarChart width={100 + (sortedData.length * 40)} height={550} data={sortedData} margin={{top: 20, right: 0, bottom: 0, left: 0}}>
                <XAxis dataKey="name" className="xAxis" tick={{fill:"#FFFFFF"}} stroke="#FFFFFF" textAnchor="end" fontSize={12} dx={-5} dy={5} height={300} angle={270}/>
                <YAxis tick={{fill:"#FFFFFF"}} stroke="#FFFFFF"/>
                <Tooltip
                    contentStyle={{ backgroundColor: "#2a3447", borderRadius: "5px"}}
                    // labelStyle={{ display: "none" }}
                    cursor={{fill:"none"}}
                    itemSorter={(item) => {
                        return item.value as number;
                    }}
                />
                <Bar dataKey="eventCount" fill="#BBC1D2" name="Количество"  label={{ position: 'top', fill:"#FFFFFF"}}/>
            </BarChart>
        </div>
        // </ResponsiveContainer>
    );
};

export default FunnelDashboard;