import React from 'react';
import "./analytics.scss"
import {Link} from "react-router-dom";



const Analytics = () => {
    return (
        <div>
            <div className="userAnalytics">
                <Link to={"/analytics/userAcquisition"}>
                    <span>Установки, Сессии</span>
                </Link>
            </div>
            <div className="funnelAnalytics">
                <Link to={"/analytics/funnelDashboard"}>
                    <span>Воронки</span>
                </Link>
            </div>
        </div>
    );
}

export default Analytics