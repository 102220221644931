import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./datePicker.scss";

type Props = {
    setMinDate: (minDate:string) => void;
    setMaxDate: (maxDate:string) => void;
};


export default function DatePickerValue(props: Props) {
    let [minDateValue, setMinDateValue] = React.useState<Dayjs | null>(dayjs('2023-11-01'));
    let [maxDateValue, setMaxDateValue] = React.useState<Dayjs | null>(dayjs());

    if (sessionStorage.getItem("minDate") != null)
    {
        var minDate = sessionStorage.getItem("minDate");
        minDateValue = dayjs(minDate)
    }

    if (sessionStorage.getItem("maxDate") != null)
    {
        var maxDate = sessionStorage.getItem("maxDate");
        maxDateValue = dayjs(maxDate)
    }

    if (minDateValue != null)
        props.setMinDate(minDateValue?.format("YYYY-MM-DD"));
    if (maxDateValue != null)
        props.setMaxDate(maxDateValue?.format("YYYY-MM-DD"));

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                    label="Мин. Дата"
                    value={minDateValue}
                    onChange={(newValue) => {
                        setMinDateValue(newValue);
                        if(newValue != null) {
                            props.setMinDate(newValue?.format("YYYY-MM-DD"));
                            sessionStorage.setItem("minDate", newValue.format("YYYY-MM-DD"));
                        }
                    }}
                    className="datePicker"
                    format="DD/MM/YYYY"
                />
                <DatePicker
                    label="Макс. Дата"
                    value={maxDateValue}
                    onChange={(newValue) => {
                        setMaxDateValue(newValue);
                        if(newValue != null){
                            props.setMaxDate(newValue?.format("YYYY-MM-DD"));
                            sessionStorage.setItem("maxDate", newValue.format("YYYY-MM-DD"));
                        }
                    }}
                    className="datePicker"
                    format="DD/MM/YYYY"
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}