import { GridColDef } from "@mui/x-data-grid";
import "./add.scss";
import axios from "axios";
import React, {useState} from "react";
import {Console} from "inspector";
// import { useMutation, useQueryClient } from "@tanstack/react-query";

type Props = {
    slug: string;
    columns: GridColDef[];
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    addElementUrl: string;
};


const Add = (props: Props) => {

    const [post, setPost] = React.useState(null);
    const addElementUrl = props.addElementUrl;

    function addElement(elementData: any) {
        axios.post(addElementUrl+sessionStorage.getItem("token"), elementData);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let elementData:any = {};
        for (let i = 0; i < e.currentTarget.elements.length - 1; i++) {
            let element_name:string = (e.currentTarget.elements[i] as HTMLInputElement).name;
            let element_value = (e.currentTarget.elements[i] as HTMLInputElement).value;

            elementData[element_name] = element_value;
        }
        addElement(elementData);
        props.setOpen(false)
    };

    return (
        <div className="add">
            <div className="modal">
        <span className="close" onClick={() => props.setOpen(false)}>
          X
        </span>
                <h1>Add new {props.slug}</h1>
                <form onSubmit={handleSubmit}>
                    {props.columns
                        .filter((item) => item.field !== "id" && item.field !== "img")
                        .map((column) => (
                            <div className="item">
                                <label>{column.headerName}</label>
                                <input name={column.field} type={column.type} placeholder={column.field} step='any'/>
                            </div>
                        ))}
                    <button>Send</button>
                </form>
            </div>
        </div>
    );
};

export default Add;