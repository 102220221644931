import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import MultipleSelect from "../../components/multipleSelect/MultipleSelect";
import DatePicker from "../../components/datePicker/DatePicker";
import FunnelDashboard from "../../components/funnelDashboard/FunnelDashboard"
import "./analyticsFunnel.scss"
import {Button} from "@mui/material";
import axios from "axios";
import {Console} from "inspector";
import dayjs from "dayjs";
import * as string_decoder from "string_decoder";



const AnalyticsFunnel = () => {
    // console.log("Analytics Init 1")
    // let data = [{'name': 'TestLevel1', 'eventCount': 5.0}, {'name': 'TestLevel2', 'eventCount': 4.0}, {'name': 'TestLevel3', 'eventCount': 2.0}, {'name': 'TestLevel4', 'eventCount': 1.0}];
    let selectedElements:string[] = ["StationCompleted"];
    let minDate = '2023-11-01';
    let maxDate = dayjs().format("YYYY-MM-DD");

    if (sessionStorage.getItem("minDate") != null)
    {
        var minDateString = sessionStorage.getItem("minDate");
        if(minDateString != null)
            minDate = minDateString
    }

    if (sessionStorage.getItem("maxDate") != null)
    {
        var maxDateString = sessionStorage.getItem("maxDate");
        if(maxDateString != null)
            maxDate = maxDateString
    }

    let getAllEventsUrl = `https://sort-station-mobile.infomedia.digital/api/v1/JzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLallevents?min_date=${minDate}&max_date=${maxDate}&token=${sessionStorage.getItem("token")}`;

    const [post, setPost] = React.useState([]);

    React.useEffect(() => {
        axios.get(getAllEventsUrl).then((response) => {
            setPost(response.data);
        });
    }, []);

    if (!post) return null;

    let data: any = post;

    if (sessionStorage.getItem("selectedElements") != null)
    {
        var savedSelectedElements = sessionStorage.getItem("selectedElements");
        if(savedSelectedElements != null){
            if (JSON.parse(savedSelectedElements) != null)
            {
                var savedSelectedElementsParsed = JSON.parse(savedSelectedElements);
                selectedElements = savedSelectedElementsParsed;
            }
        }
    }

    function setMinDate(newMinDate:string){
        minDate = newMinDate
    }

    function setMaxDate(newMaxDate:string){
        maxDate = newMaxDate
    }

    function ApplyFilters() {
        
        // data = [{'name': 'TestLevel1', 'eventCount': 51.0}, {'name': 'TestLevel2', 'eventCount': 42.0}, {'name': 'TestLevel3', 'eventCount': 23.0}, {'name': 'TestLevel4', 'eventCount': 14.0}];

        sessionStorage.setItem("minDate", minDate);
        sessionStorage.setItem("maxDate", maxDate);
        sessionStorage.setItem("selectedElements", JSON.stringify(selectedElements));

        // console.log("window.location.reload()")
        window.location.reload();
    }

    var dataNew:any = [];
    selectedElements.forEach((element) => dataNew.push(data[element]));
    // var dataNew = data["CompletedLevel"];

    return (
        <div>
            <div className="filters">
                <MultipleSelect selectedElements={selectedElements}/>
                <DatePicker setMinDate={setMinDate} setMaxDate={setMaxDate}/>
                <Button onClick={ApplyFilters}>Применить</Button>
            </div>
            {/*<DatePicker />*/}
            {/*<FunnelDashboard data={data} getAnalyticsUrl={getAllEventsUrl} />*/}
            <FunnelDashboard data={dataNew.flat()}/>
        </div>
    );
}

export default AnalyticsFunnel