import "./navbar.scss"
import React from "react";
import {Link} from "react-router-dom";
import {logoutUser} from '../../pages/auth/Auth';

const Navbar = () => {
    return(
        <div className="navbar">
            <div className="appInfo">
                <span className="appName">Панель Администратора</span>
            </div>
            <div className="logout">
                    <button type="button" onClick={logoutUser}>
                        <Link to="/">Выйти</Link>
                    </button>
            </div>
        </div>
    )
}

export default Navbar