import {
    DataGrid,
    GridColDef,
    GridToolbar,
} from "@mui/x-data-grid";
import "./dataTable.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import React, {useState} from "react";
import {Console} from "inspector";
// import { useMutation, useQueryClient } from "@tanstack/react-query";

type Props = {
    columns: GridColDef[];
    rows: object[];
    slug: string;
    deleteElementUrl: string;
    saveChangesUrl: string;
};

const DataTable = (props: Props) => {
    const deleteElementUrl = props.deleteElementUrl;
    const saveChangesUrl = props.saveChangesUrl;

    const handleDelete = (id: number) => {
        axios.post(deleteElementUrl+sessionStorage.getItem("token"),  {
                        "id": id,
                    });
    };

    let updatedItemsArray:any[] = []

    const onItemEdited = (updatedRow: any) => {
        updatedItemsArray.push(updatedRow)
        return updatedRow
    };

    const handleProcessRowUpdateError = (e: any) => {
        // console.log("handleProcessRowUpdateError");
        // console.log(e);
    };


    const saveChanges = () => {
        // console.log(updatedItemsArray);
        axios.post(saveChangesUrl+sessionStorage.getItem("token"),  {"updatedElementsArray": updatedItemsArray});
        updatedItemsArray = []
        // console.log(updatedItemsArray);
        // console.log("saveBalanceUpdates");
    };

    let actionColumn: GridColDef;

    if (props.slug == "users"){
        actionColumn = {
            field: "action",
            headerName: "Удалить",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="action">
                        <Link to={`/${props.slug}/${params.row.id}`}>
                            {/*<img src="/view.svg" alt="" />*/}
                        </Link>
                        <div className="delete">
                            <button className="deleteButton" onClick={() => handleDelete(params.row.id)}>Удалить</button>
                        </div>
                    </div>
                );
            },
        };
    }
    else{
        actionColumn = {
            field: "action",
            headerName: "",
            width: 1,
            renderCell: (params) => {
                return (
                    <div className="action">
                    </div>
                );
            },
        };
    }


    return (
        <div className="dataTable">
            <div className="save">
                <button className="saveButton" onClick={() => saveChanges()}>Сохранить</button>
            </div>
            <DataGrid
                className="dataGrid"
                rows={props.rows}
                columns={[...props.columns, actionColumn]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                    },
                }}
                pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
                disableDensitySelector
                disableColumnSelector
                processRowUpdate={(updatedRow, originalRow) =>
                    onItemEdited(updatedRow)
                }
                onProcessRowUpdateError={handleProcessRowUpdateError}
            />
        </div>
    );
};

export default DataTable;