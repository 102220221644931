import "./balance.scss"
import DataTable from "../../components/dataTable/DataTable";
import {GridColDef} from "@mui/x-data-grid";
import React, {useState} from "react";
import Add from "../../components/add/Add";
import axios from "axios";


const getBalanceConfigUrl = "https://sort-station-mobile.infomedia.digital/api/v1/JzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLbalanceconfigdatatable?token=";
const addBalanceItemUrl = "https://sort-station-mobile.infomedia.digital/api/v1/JzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLaddbalanceitem?token=";
const deleteBalanceItemUrl = "https://sort-station-mobile.infomedia.digital/api/v1/JzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLdeletebalanceitem?token=";
const saveBalanceChangesUrl = "https://sort-station-mobile.infomedia.digital/api/v1/JzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLupdatebalanceitems?token=";

const Balance = () => {
    const [open, setOpen] = useState(false);
    const [post, setPost] = React.useState(null);

    React.useEffect(() => {
        axios.get(getBalanceConfigUrl+sessionStorage.getItem("token")).then((response) => {
            setPost(response.data);
        });
    }, []);

    if (!post) return null;

    return(
        <div className="balance">
            <div className="info">
                <h1>Конфигурация Баланса</h1>
                {/*<button onClick={() => setOpen(true)}>Add New Balance Item</button>*/}
            </div>
            <DataTable slug="balanceItems" columns={post["columns"]}
                       rows={post["rows"]}
                       deleteElementUrl={deleteBalanceItemUrl}
                       saveChangesUrl={saveBalanceChangesUrl}/>
            {/*{open && <Add slug="balanceItem"*/}
            {/*              columns={post["columns"]}*/}
            {/*              setOpen={setOpen}*/}
            {/*              addElementUrl={addBalanceItemUrl}/>}*/}
        </div>
    )
}

export default Balance