import "./menu.scss"
import {Link} from "react-router-dom";
import axios from "axios";
import React from "react";

const menuUrl = "https://sort-station-mobile.infomedia.digital/api/v1/JzWNtPwFkgaVCEfYbnGsKUXpvSRyQADLmenuaccess?token=";

const Menu = () => {
    const [post, setPost] = React.useState([]);

    React.useEffect(() => {
        axios.get(menuUrl+sessionStorage.getItem("token")).then((response) => {
            setPost(response.data);
        });
    }, []);

    if (!post) return null;

    return(
        <div className="menu">
            {post.map((item:any)=>(
                <Link to={item.url} className="listItem" key={item.id}>
                    <span className="listItemTitle">{item.title}</span>
                </Link>
            ))}
        </div>
    )
}

export default Menu